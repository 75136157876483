@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* default light theme */
  .light {
    /* Base colors */
    --color-white: #fff;
    --color-black: #000;

    --color-text-high: #161413;
    --color-text-medium: #37383a;
    --color-text-low: #7f879c;

    --color-text-inverted: #fff;
    --color-text-white: #fff;
    --color-text-black: #000;

    /* background colors */
    --color-surface-0: #fff;
    --color-surface-05: #f5f6f6;
    --color-surface-10: #eceef2;
    --color-surface-20: #e5e7eb;
    --color-surface-30: #d9dbe1;
    --color-surface-40: #cccfd7;
    --color-surface-50: #b2b7c3;
    --color-surface-60: #7f879c;
    --color-surface-70: #666c7d;
    --color-surface-80: #3f434e;
    --color-surface-90: #2b2d32;

    /* Brand */
    --color-brand-primary: #4cce57;
    --color-brand-dark: #55bb5e;
    --color-brand-light: #6dd977;

    /* Other colors */
    --color-pink-light: #f7e8ef;
    --color-pink-dark: #d12b71;
    --color-blue-primary: #205eff;
    --color-blue-light: #205eff;

    --color-canvas-light: #fff;

    /* Elements */
    --color-element-0: #e7e7ed;
    --color-element-10: #e5e5ee;
    --color-element-20: #a3a3a9;
    --color-element-30: #aaaab4;
    --color-element-40: #7d7d7e;
    --color-element-50: #646464;
    --color-element-60: #323232;
  }

  /* dark theme */
  .dark {
    /* Base colors */
    --color-white: #fff;
    --color-black: #ccc;

    /* Text colors */
    --color-text-high: #fff;
    --color-text-medium: #ccc;
    --color-text-low: #ccc;

    --color-text-inverted: #161413;
    --color-text-white: #fff;
    --color-text-black: #000;

    /* background colors */
    --color-surface-0: #000;
    --color-surface-05: #2b2d32;
    --color-surface-10: #3f434e;
    --color-surface-20: #666c7d;
    --color-surface-30: #7f879c;
    --color-surface-40: #b2b7c3;
    --color-surface-50: #cccfd7;
    --color-surface-60: #d9dbe1;
    --color-surface-70: #e5e7eb;
    --color-surface-80: #eceef2;
    --color-surface-90: #f5f6f6;

    /* Brand */
    --color-brand-primary: #4cce57;
    --color-brand-dark: #55bb5e;
    --color-brand-light: #6dd977;

    /* Other colors */
    --color-pink-light: #f7e8ef;
    --color-pink-dark: #d12b71;
    --color-blue-primary: #205eff;
    --color-blue-light: #205eff;

    --color-canvas-light: #151924;

    /* Elements */
    --color-element-0: #515155;
    --color-element-10: #40444a;
    --color-element-20: #a3a3a9;
    --color-element-30: #aaaab4;
    --color-element-40: #7d7d7e;
    --color-element-50: #646464;
    --color-element-60: #323232;
  }
}

html {
  display: flex;
  font-family: 'Inter', -apple-system, Helvetica Neue, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 0;
}
